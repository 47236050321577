export const colors = [
    '#0076AE',
    '#FF7400',
    '#00A13B',
    '#EF0000',
    '#9E63B5',
    '#985247',
    '#F66EB8',
    '#7F7C77',
    '#C2BD2C',
    '#00B8E5',
]

export const telemarketFieldList = [
    { name: '名片数', val: 'oppoCnt', percentage: false, alias: '名片数', isPrice: false, desc: '' },
    {
        name: '联系',
        val: 'followCnt',
        percentage: false,
        alias: '联系',
        isPrice: false,
        desc: '系统查询有通话记录，或跟进记录有跟进。(时间产生在表单时间后，算联系)',
    },
    { name: '联系率', val: 'followCntScale', percentage: true, alias: '联系率', isPrice: false, desc: '联系/名片' },
    { name: '加微', val: 'friendCnt', percentage: false, alias: '加微', isPrice: false, desc: '' },
    { name: '加微率', val: 'friendCntScale', percentage: true, alias: '加微率', isPrice: false, desc: '加微/名片' },
    {
        name: '加微(系统)',
        val: 'hubFriendCnt',
        percentage: false,
        alias: '加微(系统)',
        isPrice: false,
        desc: '系统匹配加微',
    },
    {
        name: '加微率(系统)',
        val: 'hubFriendCntScale',
        percentage: true,
        alias: '加微率(系统)',
        isPrice: false,
        desc: '',
    },
    { name: '当日出勤', val: 'sameAttendCnt', percentage: false, alias: '当日出勤', isPrice: false, desc: '' },
    { name: '当日出勤率', val: 'sameAttendCntScale', percentage: true, alias: '当日出勤率', isPrice: false, desc: '' },
    {
        name: '当日平均出勤时长',
        val: 'sameAttendDurationScale',
        percentage: false,
        alias: '当日平均出勤时长',
        isPrice: false,
        desc: '',
    },
    {
        name: '公开课出勤',
        val: 'attendCnt',
        percentage: false,
        alias: '公开课出勤',
        isPrice: false,
        desc: '出勤时间不做限制，统计所有出勤数据',
    },
    { name: '出勤率', val: 'attendCntScale', percentage: true, alias: '出勤率', isPrice: false, desc: '出勤/名片' },
    {
        name: '平均出勤时长 (h)',
        val: 'attendDurationScale',
        percentage: false,
        alias: '平均出勤时长',
        isPrice: false,
        desc: '出勤时长/公开课出勤',
    },
    {
        name: '意向',
        val: 'intentCnt',
        percentage: false,
        alias: '意向',
        isPrice: false,
        desc: '在表单时间后，成交前或数据筛选时间前,进入意向库的。<br />在表单时间后，成交前或数据筛选时间前，进入意向库的。且意向归属和名片归属，在同一个销售名下。',
    },
    { name: '意向率', val: 'intentCntScale', percentage: true, alias: '意向率', isPrice: false, desc: '意向/名片' },
    {
        name: '成交量',
        val: 'totalTradeCnt',
        percentage: false,
        alias: '成交量',
        isPrice: false,
        desc: '全款(不包含定金)',
    },
    { name: '销转', val: 'totalTradeCntScale', percentage: true, alias: '销转', isPrice: false, desc: '成交量/名片量' },
    {
        name: '意向转化率',
        val: 'intentCntTotalTradeCnt',
        percentage: true,
        alias: '意向转化率',
        isPrice: false,
        desc: '成交/意向',
    },
    {
        name: '课后成交',
        val: 'attendTradeCnt',
        percentage: false,
        alias: '课后成交',
        isPrice: false,
        desc: '报名前看课的(不含定金)',
    },
    {
        name: '课后成交占比',
        val: 'attendTotalCntScale',
        percentage: true,
        alias: '课后成交占比',
        isPrice: false,
        desc: '课后成交/成交量',
    },
    {
        name: '到课销转',
        val: 'attendTradeCntScale',
        percentage: true,
        alias: '到课销转',
        isPrice: false,
        desc: '课后成交/出勤',
    },
    { name: '流水', val: 'totalFee', percentage: false, alias: '流水', isPrice: true, desc: '包含定金' },
    { name: 'ARPU', val: 'ARPU', percentage: false, alias: 'ARPU', isPrice: false, desc: '流水(不包含定金)/成交量' },
    { name: 'RPA', val: 'RPA', percentage: false, alias: 'RPA', isPrice: false, desc: '效率值：流水(包含定金)/名片' },
    {
        name: '当期成交',
        val: 'currentFee',
        percentage: false,
        alias: '当期成交',
        isPrice: true,
        desc: '电销模式当期：报名时间-表单时间，大于0，小于等于7',
    },
    {
        name: '当期销转',
        val: 'currentFeeScale',
        percentage: true,
        alias: '当期销转',
        isPrice: false,
        desc: '当期成单/名片',
    },
    {
        name: '跨期成交',
        val: 'periodFee',
        percentage: false,
        alias: '跨期成交',
        isPrice: true,
        desc: '电销模式当期：报名时间-表单时间，大于7',
    },
    {
        name: '跨期销转',
        val: 'periodFeeScale',
        percentage: true,
        alias: '跨期销转',
        isPrice: false,
        desc: '跨期成单/名片',
    },
]

export const advertFieldList = [
    {
        name: '预估投放成本',
        val: 'budgetCost',
        alias: '预估投放成本',
        percentage: false,
        isPrice: true,
        desc: '名片时间内预算',
    },
    { name: '预估名片量', val: 'budgetOppoCnt', alias: '预估名片量', percentage: false, isPrice: false },
    { name: '预估CPA', val: 'budgetCPA', alias: '预估CPA', percentage: false, isPrice: false },
    {
        name: '投放成本占比',
        val: 'totalCostScale',
        percentage: true,
        alias: '投放成本占比',
        isPrice: false,
        desc: '占总计的比值',
        unChartField: true,
    },
    { name: '投放成本', val: 'cost', percentage: false, alias: '投放成本', isPrice: true, desc: '' },
    { name: '流水', val: 'totalFee', percentage: false, alias: '流水', isPrice: true, desc: '包含定金' },
    { name: '二阶流水', val: 'boTotalFee', percentage: false, alias: '二阶流水', isPrice: true, desc: '' },
    { name: '费比', val: 'costScale', percentage: true, alias: '费比', isPrice: false, desc: '投放成本/流水' },
    {
        name: '二阶费比',
        val: 'allCostScale',
        percentage: true,
        alias: '二阶费比',
        isPrice: false,
        desc: '投放成本/(一阶流水+二阶流水)',
    },
    {
        name: '展现',
        val: 'impression',
        percentage: false,
        alias: '展现',
        isPrice: false,
        desc: '展示(信息流、sem)、阅读(kol-微信公众号)',
    },
    { name: 'ECPM', val: 'ECPM', percentage: false, alias: 'ECPM', isPrice: false, desc: '投放成本/展示*1000' },
    { name: '点击', val: 'click', percentage: false, alias: '点击', isPrice: false, desc: '' },
    { name: 'CPC', val: 'CPC', percentage: false, alias: 'CPC', isPrice: false, desc: '投放成本/点击' },
    { name: 'CTR', val: 'CTR', percentage: true, alias: 'CTR', isPrice: false, desc: '点击/展示' },
    { name: '表单', val: 'formCnt', percentage: false, alias: '表单', isPrice: false, desc: '' },
    { name: 'CVR', val: 'CVR', percentage: true, alias: 'CVR', isPrice: false, desc: '表单数/点击' },
    { name: 'X值', val: 'X', percentage: false, alias: 'X值', isPrice: false, desc: '表单数/展示*10000' },
    {
        name: '名片量占比',
        val: 'totalOppoCntScale',
        percentage: true,
        alias: '名片量占比',
        isPrice: false,
        desc: '占总计的比值',
        unChartField: true,
    },
    { name: '名片', val: 'oppoCnt', percentage: false, alias: '名片', isPrice: false, desc: '' },
    { name: '名片/表单', val: 'numFormOppo', percentage: true, alias: '名片/表单', isPrice: false, desc: '' },
    { name: 'CPA', val: 'CPA', percentage: false, alias: 'CPA', isPrice: false, desc: '名片成本：投放成本/名片量' },
    { name: '加微', val: 'friendCnt', percentage: false, alias: '加微', isPrice: false, desc: '' },
    { name: '加微率', val: 'friendCntScale', percentage: true, alias: '加微率', isPrice: false, desc: '加微/名片' },
    {
        name: '公众号关注',
        val: 'subscribeCnt',
        percentage: false,
        alias: '公众号关注',
        isPrice: false,
        desc: '包含取关用户',
    },
    { name: '公众号关注率', val: 'subscribeCntScale', percentage: true, alias: '公众号关注', isPrice: false },
    { name: '公众号取关', val: 'unsubscribeCnt', percentage: false, alias: '公众号取关', isPrice: false },
    { name: '公众号取关率', val: 'unsubscribeCntScale', percentage: true, alias: '公众号取关', isPrice: false },
    {
        name: '公开课出勤',
        val: 'attendCnt',
        percentage: false,
        alias: '公开课出勤',
        isPrice: false,
        desc: '同SKU下的公开课出勤时长；时间周期在表单时间后，成交前或数据筛选时间前',
    },
    { name: '出勤率', val: 'attendCntScale', percentage: true, alias: '出勤率', isPrice: false, desc: '出勤/名片' },
    {
        name: '平均出勤时长 (h)',
        val: 'attendDurationScale',
        percentage: false,
        alias: '平均出勤时长 (h)',
        isPrice: false,
        desc: '出勤时长/公开课出勤',
    },
    {
        name: '意向',
        val: 'intentCnt',
        percentage: false,
        alias: '意向',
        isPrice: false,
        desc: '在表单时间后，成交前或数据筛选时间前。进入意向库的',
    },
    { name: '意向率', val: 'intentCntScale', percentage: true, alias: '意向率', isPrice: false, desc: '意向/名片' },
    {
        name: '成交量',
        val: 'totalTradeCnt',
        percentage: false,
        alias: '成交量',
        isPrice: false,
        desc: '全款(不包含定金)',
    },
    { name: '销转', val: 'totalTradeCntScale', percentage: true, alias: '销转', isPrice: false, desc: '成交量/名片量' },
    {
        name: '意向转化率',
        val: 'intentTradeCntScale',
        percentage: true,
        alias: '意向转化率',
        isPrice: false,
        desc: '成交/意向',
    },
    {
        name: '课后成交',
        val: 'attendTradeCnt',
        percentage: false,
        alias: '课后成交',
        isPrice: false,
        desc: '报名前看课的(不含定金)',
    },
    {
        name: '到课销转',
        val: 'attendTradeCntScale',
        percentage: true,
        alias: '到课销转',
        isPrice: false,
        desc: '课后成交/出勤',
    },
    {
        name: '课后成交占比',
        val: 'attendTotalCntScale',
        percentage: true,
        alias: '课后成交占比',
        isPrice: false,
        desc: '课后成交/成交量',
    },
    { name: 'ARPU', val: 'ARPU', percentage: false, alias: 'ARPU', isPrice: false, desc: '流水(不包含定金)/成交量' },
    { name: 'RPA', val: 'RPA', percentage: false, alias: 'RPA', isPrice: false, desc: '效率值：流水/名片' },
    {
        name: '当期流水',
        val: 'currentFee',
        percentage: false,
        alias: '当期流水',
        isPrice: true,
        desc: '社群模式当期：day7晚24点<br /> 电销模式当期：报名时间-表单时间，大于0，小于等于7',
    },
    {
        name: '当期销转',
        val: 'currentTradeCntScale',
        percentage: true,
        alias: '当期销转',
        isPrice: false,
        desc: '当期成单/名片',
    },
    {
        name: '当期ROI',
        val: 'currentFeeRoi',
        percentage: false,
        alias: '当期ROI',
        isPrice: false,
        desc: '跨期流水/投放成本',
    },
    {
        name: '跨期流水',
        val: 'periodFee',
        percentage: false,
        alias: '跨期流水',
        isPrice: true,
        desc: '社群模式当期：day7晚24点 <br/>电销模式当期：报名时间-表单时间，大于7',
    },
    {
        name: '跨期销转',
        val: 'periodTradeCntScale',
        percentage: true,
        alias: '跨期销转',
        isPrice: false,
        desc: '跨期成单/名片',
    },
    {
        name: '跨期ROI',
        val: 'periodFeeRoi',
        percentage: false,
        alias: '跨期ROI',
        isPrice: false,
        desc: '跨期流水/投放成本',
    },
]

export const advertTableFieldList = [
    { name: '投放成本占比', val: 'totalCostScale' },
    { name: '投放成本', val: 'cost' },
    { name: '流水', val: 'totalFee' },
    { name: '费比', val: 'costScale' },
    { name: '展现', val: 'impressionText' },
    { name: 'ECPM', val: 'ECPM' },
    { name: '点击', val: 'click' },
    { name: 'CPC', val: 'CPC' },
    { name: 'CTR', val: 'CTR' },
    { name: '表单', val: 'formCnt' },
    { name: 'CVR', val: 'CVR' },
    { name: 'X值', val: 'X' },
    { name: '名片量占比', val: 'totalOppoCntScale' },
    { name: '名片', val: 'oppoCnt' },
    { name: '名片/表单', val: 'numFormOppo' },
    { name: 'CPA', val: 'CPA' },
    { name: '加微', val: 'friendCnt' },
    { name: '加微率', val: 'friendCntScale' },
    { name: '公开课出勤', val: 'attendCnt' },
    { name: '出勤率', val: 'attendCntScale' },
    { name: '平均出勤时长', val: 'attendDurationScale' },
    { name: '意向', val: 'intentCnt' },
    { name: '意向率', val: 'intentCntScale' },
    { name: '成交量', val: 'totalTradeCnt' },
    { name: '销转', val: 'totalTradeCntScale' },
    { name: '意向转化率', val: 'intentTradeCntScale' },
    { name: '课后成交', val: 'attendTradeCnt' },
    { name: '课后成交占比', val: 'attendTotalCntScale' },
    { name: 'ARPU', val: 'ARPU' },
    { name: 'RPA', val: 'RPA' },
    { name: '当期流水', val: 'currentFee' },
    { name: '当期销转', val: 'currentTradeCntScale' },
    { name: '当期ROI', val: 'currentFeeRoi' },
    { name: '跨期流水', val: 'periodFee' },
    { name: '跨期销转', val: 'periodTradeCntScale' },
    { name: '跨期ROI', val: 'periodFeeRoi' },
]

export const scopeMap = {
    TEL: '电销',
    KOL: '社群',
    NONE: '未知',
    BO: '二阶',
}

export const refundPeriodMap = {
    1: '0-1天',
    7: '1-7天',
    30: '8-30天',
    60: '31-60天',
    90: '61-90天',
    180: '91-180天',
    999: '180天以上',
}

export const refundPeriodList = [
    { val: 1, name: '0-1天' },
    { val: 7, name: '1-7天' },
    { val: 30, name: '8-30天' },
    { val: 60, name: '31-60天' },
    { val: 90, name: '61-90天' },
    { val: 180, name: '91-180天' },
    { val: 999, name: '180天以上' },
]

export const publicLiveFieldList = [
    { name: '直播ROI', val: 'liveROI', percentage: true, alias: '直播ROI', isPrice: false, desc: '直播GMV/投放花费' },
    {
        name: '费比',
        val: 'costScale',
        percentage: true,
        alias: '费比',
        isPrice: false,
        desc: '(投放花费+直播课酬-直播GMV+实物成本)/流水',
    },
    { name: 'ECPM', val: 'ECPM', percentage: false, alias: 'ECPM', isPrice: false, desc: '投放花费/展示*1000' },
    {
        name: '曝光进入率(CTR)次数',
        val: 'CTR',
        percentage: true,
        alias: '曝光进入率(CTR)次数',
        isPrice: false,
        desc: '观看(次数)/展现(次数)',
    },
    { name: 'CPC', val: 'CPC', percentage: true, alias: 'CPC', isPrice: false, desc: '投放花费/观看(次数)' },
    {
        name: '自然流量占比',
        val: 'freeViewPvScale',
        percentage: true,
        alias: '自然流量占比',
        isPrice: false,
        desc: '自然流量观看(次数)/观看(次数)',
    },
    {
        name: '观看-互动率(人数)',
        val: 'interactScale',
        percentage: true,
        alias: '观看-互动率(人数)',
        isPrice: false,
        desc: '互动(人数)/观看(人数)',
    },
    {
        name: '观看-关注率(人数)',
        val: 'newFanCntScale',
        percentage: true,
        alias: '观看-关注率(人数)',
        isPrice: false,
        desc: '新增粉丝数/观看(人数)',
    },
    {
        name: '观看-加粉丝团(人数)',
        val: 'newVipCntScale',
        percentage: true,
        alias: '观看-加粉丝团(人数)',
        isPrice: false,
        desc: '新加粉丝团/观看(人数)',
    },
    { name: 'GPM', val: 'GPM', percentage: false, alias: 'GPM', isPrice: false, desc: '直播GMV/观看(次数)*1000' },
    { name: 'CVR', val: 'CVR', percentage: true, alias: 'CVR', isPrice: false, desc: '表单/观看(人数)' },
    {
        name: 'CPA',
        val: 'CPA',
        percentage: false,
        alias: 'CPA',
        isPrice: false,
        desc: '(投放花费+直播课酬-直播GMV+实物成本)/名片',
    },
    {
        name: '当期ROI',
        val: 'currentFeeRoi',
        percentage: false,
        alias: '当期ROI',
        isPrice: false,
        desc: '当期流水/(投放花费+直播课酬-直播GMV+实物成本)',
    },
    {
        name: '跨期ROI',
        val: 'periodFeeRoi',
        percentage: false,
        alias: '跨期ROI',
        isPrice: false,
        desc: '跨期流水/(投放花费+直播课酬-直播GMV+实物成本)',
    },
]
